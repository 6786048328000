import React from 'react'
import { useTranslation } from "react-i18next";
import LineChart from "../../../../../../components/Analytics/LineChart/LineChart";
import ChartBarComponent from "../../../../../../components/Analytics/ChartBarComponent/ChartBarComponent";
import DonutChart from "../../../../../../components/Analytics/DonutChart/DonutChart";
import CustomDatePicker from "../../../../../../components/CustomDatePicker/CustomDatePicker";
import DropdownSelector from "../../../../../../components/Analytics/DropdownSelector/DropdownSelector";
import EmptyData from "../../../../../../components/Analytics/EmptyData/EmptyData";
import styles from './Audience.module.css'

export default function Audience({ageData, changePeriod, status, genders, type, audience_chart, genders_array, changeDate,
     analyticClientsDateFrom,
     analyticClientsDateTo
}) {
    const { t } = useTranslation();
    let audience_count = 0
    audience_chart.map((item, index) => index > 0 && (audience_count += item[1]))
    let age_count = 0;
    ageData.map((item, index) => index > 0 && (age_count += item[1]))
    let gender_count = 0
    genders_array.map(item => gender_count += item.y)


    return (
        <div className={styles.container}>
            <h1>{t('NSAnalytics.customer')}</h1>
            <div className={styles.datesPickers}>
                {/*<DropdownSelector changePeriod={changePeriod} status={status} type={type}/>*/}
                <div style={{marginLeft: 34}}>
                    <CustomDatePicker
                      type={type}
                      trans
                      tStartDate={analyticClientsDateFrom}
                      tEndDate={analyticClientsDateTo}
                      tSetStartDate={(v) => changeDate(1, v)}
                      tSetEndDate={(v) => changeDate(2, v)}
                    />
                </div>
            </div>
            {audience_count + age_count + gender_count ?
                <>
                    <div style={{marginTop: 25}}>
                        <LineChart
                          showTypes={false}
                          showDatePicker={false}
                            type={type}
                            data={audience_count > 0 ? audience_chart : audience_chart = [
                                ['x', t('clientsTo')],
                                [t('mn'),parseInt(null)],
                                [t('tu'),parseInt(null)],
                                [t('wd'),parseInt(null)],
                                [t('th'),parseInt(null)],
                                [t('fr'),parseInt(null)],
                                [t('sn'),parseInt(null)],
                                [t('st'),parseInt(null)],
                            ]}
                            changePeriod={changePeriod}
                        />
                    </div>
                    <div className={styles.bottomCharts}>
                        <div className={styles.chartBar}>
                            <p style={{ margin: '0 0 30px 0' }}>{t('age')}</p>
                            {/*<div style={{marginBottom: 35}}>*/}
                            {/*    <CustomDatePicker type={type}/>*/}
                            {/*</div>*/}
                            {/*<div style={{maxWidth: 400}}>*/}
                            <div>
                                <ChartBarComponent
                                    width={20}
                                    data={ageData}
                                    percents={true}
                                />
                            </div>

                        </div>

                        {/*<div className={styles.donutChart}>*/}
                        {/*    <DonutChart*/}
                        {/*        title={t('gender')}*/}
                        {/*        data={[*/}
                        {/*            ['x', 'y', {role: "tooltip", type: "string", p: {html: true}}],*/}
                        {/*            [t('male'), genders[0].y ? genders[0].y : 0, `<div class='donutChartContainer_green'><span>${genders[0].y}%</span></div>`],*/}
                        {/*            [t('female'), genders[1].y ? genders[1].y : 0, `<div class='donutChartContainer_purple'><span>${genders[1].y}%</span></div>`],*/}
                        {/*            [t('unknown'), genders[2].y ? genders[2].y : 0, `<div class='donutChartContainer_gray'><span>${genders[2].y}%</span></div>`],*/}
                        {/*        ]}*/}
                        {/*        subTitleData={[genders_array[0].y ? genders_array[0].y : 0, genders_array[1].y ? genders_array[1].y : 0, genders_array[2].y ? genders_array[2].y : 0]}*/}
                        {/*        legendData={[*/}
                        {/*            {color: '#73D389', title: t('male')},*/}
                        {/*            {color: '#705AFB', title: t('female')},*/}
                        {/*            {color: '#E7E7E6', title: t('unknown')},*/}
                        {/*        ]}*/}
                        {/*        marginTopTitle={'0 0 40px 0'}*/}
                        {/*    />*/}

                        {/*</div>*/}

                    </div>
                </>
                :
                <EmptyData marginTop={30}/>
            }
        </div>
    )
}