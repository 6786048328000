import clientsTypes from "./clientsTypes";
import clientsApi from "./clientsApi";
import { Roles } from "../../config";

const clientsActions = {
    getClients: (id, params, searchRef = null) => dispatch => {
        dispatch({
            type: clientsTypes.GET_CLIENTS_PENDING
        })
        const { roles = [], groupId = '', invisParams, ...otherParams } = params
        if (roles.includes(Roles.ANALYTIC) && groupId) {
            clientsApi.getAnalyticClients(groupId, otherParams, invisParams)
              .then(res => {
                  dispatch({
                      type: clientsTypes.GET_CLIENTS_SUCCESS,
                      payload: res.data
                  })
              })
              .catch(() => {
                  dispatch({
                      type: clientsTypes.GET_CLIENTS_ERROR
                  })
              })
              .finally(() => searchRef && searchRef.current && searchRef.current.focus())
        } else {
            clientsApi.getClients(id, otherParams)
              .then(res => {
                  dispatch({
                      type: clientsTypes.GET_CLIENTS_SUCCESS,
                      payload: res.data
                  })
              })
              .catch(() => {
                  dispatch({
                      type: clientsTypes.GET_CLIENTS_ERROR
                  })
              })
              .finally(() => searchRef && searchRef.current && searchRef.current.focus())
        }
    },

    getClientsDatabase: (id, params, searchRef = null) => dispatch => {
        dispatch({
            type: clientsTypes.GET_CLIENTS_PENDING
        })
        const { currentRole = [], groupId = '', invisParams, ...otherParams } = params
        if (currentRole === Roles.ANALYTIC && groupId) {
            clientsApi.getAnalyticClientsDatabase(groupId, otherParams, invisParams)
              .then(res => {
                  dispatch({
                      type: clientsTypes.GET_CLIENTS_SUCCESS,
                      payload: res.data
                  })
              })
              .catch(() => {
                  dispatch({
                      type: clientsTypes.GET_CLIENTS_ERROR
                  })
              })
              .finally(() => searchRef && searchRef.current && searchRef.current.focus())
        } else {
            clientsApi.getClientsDatabase(id, otherParams)
              .then(res => {
                  dispatch({
                      type: clientsTypes.GET_CLIENTS_SUCCESS,
                      payload: res.data
                  })
              })
              .catch(() => {
                  dispatch({
                      type: clientsTypes.GET_CLIENTS_ERROR
                  })
              })
              .finally(() => searchRef && searchRef.current && searchRef.current.focus())
        }

    },
    changePage:(page) => {
        return {
            type:clientsTypes.CHANGE_CLIENTS_PAGE,
            payload: page
        }
    },

    changeChosenClients: (c) => {
        const customer = Object.assign(c);
        customer.checked = !c.checked;

        return {
            type: clientsTypes.CHANGE_CHOSEN_CLIENTS,
            payload: customer
        }
    },

    clearChosenClients: () => ({ type: clientsTypes.CLEAR_CHOSEN_CLIENTS }),
}

export default clientsActions
