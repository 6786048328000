import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import CustomDatePicker from "../../../../../../components/CustomDatePicker/CustomDatePicker";
import { useCurrentBranch } from "../../../../../../hooks/useCurrentBranch";
import { PENDING } from "../../../../../../store/globalStatuses/globalStatuses";
import TextField from "../../../../../../components/FormElements/TextField";
import findImg from "../../../../../../assets/icons/find.svg";
import styles from './BusinessReport.module.css';
import DonutChart from "../../../../../../components/Analytics/DonutChart/DonutChart";
import ButtonFilled from "../../../../../../components/FormElements/ButtonFilled";
import { convertDataToTransactions } from "../../../../../../tools/convertDateToString";
import businessReportApi from "../../../../../../store/analyticsStores/businessReportStore/businessReportApi";

const arrowsSvg = (
  <svg width="17" height="14" viewBox="0 0 17 14" fill="none">
    <path d="M4.4 13C4.4 13.3314 4.66863 13.6 5 13.6C5.33137 13.6 5.6 13.3314 5.6 13L4.4 13ZM5.42426 0.575736C5.18995 0.341421 4.81005 0.341421 4.57574 0.575736L0.757359 4.39411C0.523045 4.62843 0.523045 5.00833 0.757359 5.24264C0.991674 5.47696 1.37157 5.47696 1.60589 5.24264L5 1.84853L8.39411 5.24264C8.62843 5.47696 9.00833 5.47696 9.24264 5.24264C9.47696 5.00833 9.47696 4.62843 9.24264 4.39411L5.42426 0.575736ZM5.6 13L5.6 1L4.4 1L4.4 13L5.6 13Z" fill="black"/>
    <path d="M11.4 1C11.4 0.668629 11.6686 0.4 12 0.4C12.3314 0.4 12.6 0.668629 12.6 1L11.4 1ZM12.4243 13.4243C12.19 13.6586 11.8101 13.6586 11.5757 13.4243L7.75736 9.60589C7.52304 9.37157 7.52304 8.99167 7.75736 8.75736C7.99167 8.52304 8.37157 8.52304 8.60589 8.75736L12 12.1515L15.3941 8.75736C15.6284 8.52304 16.0083 8.52304 16.2426 8.75736C16.477 8.99167 16.477 9.37157 16.2426 9.60589L12.4243 13.4243ZM12.6 1L12.6 13L11.4 13L11.4 1L12.6 1Z" fill="black"/>
  </svg>
);

export default function BusinessReport(props) {
    const {
      sort_type,
      setSortType,
      reports,
      endDate,
      page,
      setEndDate,
      setStartDate,
      startDate,
      changePageAction,
      // count,
      customer,
      info,
      status,
      branch_name,
      setBranchName,
      searchRef,
      getBussinessReport,
    } = props
    const { currentRole } = useCurrentBranch();
    const [downloading, setDownloading] = useState(false);
    const { t } = useTranslation();

    const exportExcel = () => {
      const params = {};
      if (branch_name) {
        params.branch_name = branch_name;
      }
      if (startDate) {
        params.from = convertDataToTransactions(startDate);
      }
      if (endDate) {
        params.to = convertDataToTransactions(endDate);
      }
      setDownloading(true);
      businessReportApi.getBusinessReportDataExport(info.id, params)
        .finally(() => setDownloading(false));
    }

    return (
      <div className={styles.container}>
        <h1>{t('NSSidebar.businessReport')}</h1>
        <div style={{maxWidth: 450, width: '100%', marginBottom: "1rem"}}>
          <TextField
            ref={searchRef}
            icon={findImg}
            placeholder={t('searchByAddress')}
            value={branch_name}
            disabled={status === PENDING}
            onChange={(e) => setBranchName(e.target.value)}
          />
        </div>
        <h2 class={styles.preTitle}>{t("NSCrossMarketing.choosePeriod")}</h2>
        <div className={styles.dates}>
          <CustomDatePicker
            trans={true}
            tStartDate={startDate}
            tEndDate={endDate}
            tSetStartDate={setStartDate}
            tSetEndDate={setEndDate}
            changePageAction={changePageAction}
          />
        </div>
        <table className={`table ${status === PENDING ? 'loading' : ''}`}>
          <thead>
          <tr>
            <th>#</th>
            <th>{t('title')}</th>
            <th>
              <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }} onClick={() => sort_type === '1' ? setSortType('-1') : setSortType('1')}>
                <span>{t('NSGeneral.sales')}</span>
                <i>{arrowsSvg}</i>
              </div>
            </th>
            <th>{t('transactions')}</th>
            <th>{t('NSGeneral.average')}</th>
            <th>{t('NSGeneral.bonuses')}</th>
            <th>{t('NSGeneral.generalClients')}</th>
            <th>{t('NSGeneral.loyalClients')}</th>
          </tr>
          </thead>
          <tbody>
            {reports && reports.length ? reports.map((report, index) => (
              <tr
                style={{
                  color: index === reports.length - 1 ? 'green' : 'black',
                  textTransform: index === reports.length - 1 ? 'uppercase' : 'none',
              }}
              >
                <td></td>
                <td
                  style={{ color: index === reports.length - 1 ? 'green' : '#333' }}
                >{report.branch_name}</td>
                <td
                  style={{ color: index === reports.length - 1 ? 'green' : '#333' }}
                >{Number(report.orders_total_amount).toFixed(2)}</td>
                <td
                  style={{ color: index === reports.length - 1 ? 'green' : '#333' }}
                >{report.orders_count}</td>
                <td
                  style={{ color: index === reports.length - 1 ? 'green' : '#333' }}
                >{Number(report.avg_orders_total_amount).toFixed(2)}</td>
                <td
                  style={{ color: index === reports.length - 1 ? 'green' : '#333' }}
                >{Number(report.orders_cashback_amount).toFixed(2)}</td>
                <td
                  style={{ color: index === reports.length - 1 ? 'green' : '#333' }}
                >{report.clients_count}</td>
                <td
                  style={{ color: index === reports.length - 1 ? 'green' : '#333' }}
                >{report.loyal_clients_amount}</td>
              </tr>
            )) : (
              <tr>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
              </tr>
            )}
          </tbody>
        </table>
        {/*<div>Pagination</div>*/}
        {/*<div style={{ margin: "20px 0" }}>*/}
        {/*  <DonutChart*/}
        {/*    title={t('NSLoyal.sales')}*/}
        {/*    data={[]}*/}
        {/*    subTitleData={[]}*/}
        {/*    // data={[*/}
        {/*    //   ['x', 'y', {role: "tooltip", type: "string", p: {html: true}}],*/}
        {/*    //   [t('loyal'), Math.round(returned_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_green'><span>${Math.round(returned_amount / (returned_amount + news_amount) * 100)}%</span></div>`],*/}
        {/*    //   [t('new'), Math.round(news_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_purple'><span>${Math.round(news_amount / (returned_amount + news_amount) * 100)}%</span></div>`],*/}
        {/*    // ]}*/}
        {/*    // subTitleData={[returned_amount, news_amount]}*/}
        {/*    legendData={[*/}
        {/*      {color: '#73D389', title: t('loyal')},*/}
        {/*      {color: '#705AFB', title: t('new')}*/}
        {/*    ]}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <DonutChart*/}
        {/*    title={t('NSTransModal.tranc')}*/}
        {/*    data={[]}*/}
        {/*    subTitleData={[]}*/}
        {/*    // data={[*/}
        {/*    //   ['x', 'y', {role: "tooltip", type: "string", p: {html: true}}],*/}
        {/*    //   [t('loyal'), Math.round(returned_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_green'><span>${Math.round(returned_amount / (returned_amount + news_amount) * 100)}%</span></div>`],*/}
        {/*    //   [t('new'), Math.round(news_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_purple'><span>${Math.round(news_amount / (returned_amount + news_amount) * 100)}%</span></div>`],*/}
        {/*    // ]}*/}
        {/*    // subTitleData={[returned_amount, news_amount]}*/}
        {/*    legendData={[*/}
        {/*      {color: '#73D389', title: t('loyal')},*/}
        {/*      {color: '#705AFB', title: t('new')}*/}
        {/*    ]}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <DonutChart*/}
        {/*    title={t('NSGeneral.average')}*/}
        {/*    data={[]}*/}
        {/*    subTitleData={[]}*/}
        {/*    // data={[*/}
        {/*    //   ['x', 'y', {role: "tooltip", type: "string", p: {html: true}}],*/}
        {/*    //   [t('loyal'), Math.round(returned_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_green'><span>${Math.round(returned_amount / (returned_amount + news_amount) * 100)}%</span></div>`],*/}
        {/*    //   [t('new'), Math.round(news_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_purple'><span>${Math.round(news_amount / (returned_amount + news_amount) * 100)}%</span></div>`],*/}
        {/*    // ]}*/}
        {/*    // subTitleData={[returned_amount, news_amount]}*/}
        {/*    legendData={[*/}
        {/*      {color: '#73D389', title: t('loyal')},*/}
        {/*      {color: '#705AFB', title: t('new')}*/}
        {/*    ]}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <DonutChart*/}
        {/*    title={t('NSPayment.assessment')}*/}
        {/*    data={[]}*/}
        {/*    subTitleData={[]}*/}
        {/*    // data={[*/}
        {/*    //   ['x', 'y', {role: "tooltip", type: "string", p: {html: true}}],*/}
        {/*    //   [t('loyal'), Math.round(returned_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_green'><span>${Math.round(returned_amount / (returned_amount + news_amount) * 100)}%</span></div>`],*/}
        {/*    //   [t('new'), Math.round(news_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_purple'><span>${Math.round(news_amount / (returned_amount + news_amount) * 100)}%</span></div>`],*/}
        {/*    // ]}*/}
        {/*    // subTitleData={[returned_amount, news_amount]}*/}
        {/*    legendData={[*/}
        {/*      {color: '#73D389', title: t('loyal')},*/}
        {/*      {color: '#705AFB', title: t('new')}*/}
        {/*    ]}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <DonutChart*/}
        {/*    title={t('NSAds.amountClients')}*/}
        {/*    data={[]}*/}
        {/*    subTitleData={[]}*/}
        {/*    // data={[*/}
        {/*    //   ['x', 'y', {role: "tooltip", type: "string", p: {html: true}}],*/}
        {/*    //   [t('loyal'), Math.round(returned_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_green'><span>${Math.round(returned_amount / (returned_amount + news_amount) * 100)}%</span></div>`],*/}
        {/*    //   [t('new'), Math.round(news_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_purple'><span>${Math.round(news_amount / (returned_amount + news_amount) * 100)}%</span></div>`],*/}
        {/*    // ]}*/}
        {/*    // subTitleData={[returned_amount, news_amount]}*/}
        {/*    legendData={[*/}
        {/*      {color: '#73D389', title: t('loyal')},*/}
        {/*      {color: '#705AFB', title: t('new')}*/}
        {/*    ]}*/}
        {/*  />*/}
        {/*</div>*/}
        {/*<div>*/}
        {/*  <DonutChart*/}
        {/*    title={t('NSGeneral.loyalClients')}*/}
        {/*    data={[]}*/}
        {/*    subTitleData={[]}*/}
        {/*    // data={[*/}
        {/*    //   ['x', 'y', {role: "tooltip", type: "string", p: {html: true}}],*/}
        {/*    //   [t('loyal'), Math.round(returned_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_green'><span>${Math.round(returned_amount / (returned_amount + news_amount) * 100)}%</span></div>`],*/}
        {/*    //   [t('new'), Math.round(news_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_purple'><span>${Math.round(news_amount / (returned_amount + news_amount) * 100)}%</span></div>`],*/}
        {/*    // ]}*/}
        {/*    // subTitleData={[returned_amount, news_amount]}*/}
        {/*    legendData={[*/}
        {/*      {color: '#73D389', title: t('loyal')},*/}
        {/*      {color: '#705AFB', title: t('new')}*/}
        {/*    ]}*/}
        {/*  />*/}
        {/*</div>*/}
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
          <ButtonFilled
            onClick={exportExcel}
            loading={downloading}
          >{t("export")}</ButtonFilled>
        </div>
      </div>
    )
}
