import React, { useEffect, useRef, useState } from 'react'
import BusinessReport from "./components/BusinessReport/BusinessReport";
import { useDispatch, useSelector } from "react-redux";
import debounce from "../../../../tools/debounce";
import transactionsActions from "../../../../store/analyticsStores/businessReportStore/businessReportActions";
import businessReportTypes from "../../../../store/analyticsStores/businessReportStore/businessReportTypes";

export default function BusinessReportContainer() {
    const dispatch = useDispatch()
    const {info} = useSelector(state => state.branchReducer)
    const {page, data, status} = useSelector(state => state.businessReportReducer)
    const {payment_status} = useSelector(state => state.bonusesModalReducer)
    const {customer} = useSelector(state => state.clientInfoReducer)
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [sort_type, setSortType] = useState('1')
    const [branch_name, setBranchName] = useState('')
    const searchRef = useRef(null);

    const getBussinessReport = () => {
        dispatch(transactionsActions.getBusinessReportData(info && info.id));
    }

    useEffect(() => {
        if (info) {
            debounce(()=>dispatch(transactionsActions.getBusinessReportData(info && info.id, startDate, endDate, branch_name, sort_type)), 100)
        }
    }, [page, info, sort_type, endDate, startDate, payment_status])

    useEffect(() => {
        if (info) {
            dispatch({ type: businessReportTypes.CLEAR_BUSINESS_REPORT })
            debounce(()=>dispatch(transactionsActions.getBusinessReportData(info && info.id, startDate, endDate, branch_name, sort_type)), 1500)
        }
    }, [branch_name, info])

    const changePageAction = (page) => {
        dispatch(transactionsActions.changePage(page))
    }

    return (
        <BusinessReport
            sort_type={sort_type}
            setSortType={setSortType}
            searchRef={searchRef}
            reports={data}
            getBussinessReport={getBussinessReport}
            // count={data.count}
            page={page}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            changePageAction={changePageAction}
            customer={customer}
            info={info}
            status={status}
            branch_name={branch_name}
            setBranchName={setBranchName}
        />
    )
}
