import React from 'react'
import { useTranslation } from "react-i18next";
import Numbers, { AllNumbers } from "../../../../../../components/Analytics/Numbers/Numbers";
import DonutChart from "../../../../../../components/Analytics/DonutChart/DonutChart";
import CustomDatePicker from "../../../../../../components/CustomDatePicker/CustomDatePicker";
import DropdownSelector from "../../../../../../components/Analytics/DropdownSelector/DropdownSelector";
import EmptyData from "../../../../../../components/Analytics/EmptyData/EmptyData";
import styles from './Loyal.module.css'

export default function Loyal(props) {
    const {
        data,
        news_amount,
        news_average_check,
        news_clients,
        news_transactions,
        returned_amount,
        returned_average_check,
        returned_clients,
        returned_transactions,
        status,
        changePeriod,
        type,
        startDate,
        setStartDate,
        endDate,
        setEndDate,
    } = props
    const { t } = useTranslation();

    const check = news_amount + news_average_check + news_clients + news_transactions + returned_amount + returned_average_check + returned_clients + returned_transactions

    return (
        <div className={styles.container}>
            <h1>{t('NSLoyal.loyal')}</h1>
            <div className={styles.datesPickers}>
                {/*<DropdownSelector changePeriod={changePeriod} status={status} type={type}/>*/}
                <div style={{marginLeft: 34}}>
                    <CustomDatePicker
                      trans={true}
                      tStartDate={startDate}
                      tEndDate={endDate}
                      tSetStartDate={(v) => setStartDate(v)}
                      tSetEndDate={(v) => setEndDate(v)}
                      type={type}
                    />
                </div>
            </div>
            {check !== 0 ?
              <>
                  <div className={styles.numbersWrapper}>
                      <div className={styles.numbers}>
                          <h2>{t('loyal')}</h2>
                          <Numbers
                            loyal={true}
                            average_check={returned_average_check}
                            cashback_amount={returned_clients}
                            payment_total={returned_amount}
                            transactions={returned_transactions}
                          />
                      </div>
                      <div className={styles.numbers}>
                          <h2>{t('new')}</h2>
                          <Numbers
                            loyal={true}
                            purple={true}
                            average_check={news_average_check}
                            cashback_amount={news_clients}
                            payment_total={news_amount}
                            transactions={news_transactions}
                          />
                      </div>
                      <div className={styles.numbers}>
                          <h2>{t('NSAds.amountClients')}</h2>
                          <AllNumbers
                            loyal={true}
                            // average_check={returned_average_check}
                            cashback_amount={(returned_clients || 0) + (news_clients || 0)}
                            // payment_total={returned_amount}
                            // transactions={returned_transactions}
                          />
                      </div>
                  </div>
                  <div className={styles.chartsWrapper}>
                      <div className={styles.chart}>
                          <DonutChart
                            title={t('NSLoyal.sales')}
                            data={[
                                ['x', 'y', {role: "tooltip", type: "string", p: {html: true}}],
                                [t('loyal'), Math.round(returned_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_green'><span>${Math.round(returned_amount / (returned_amount + news_amount) * 100)}%</span></div>`],
                                [t('new'), Math.round(news_amount / (returned_amount + news_amount) * 100), `<div class='donutChartContainer_purple'><span>${Math.round(news_amount / (returned_amount + news_amount) * 100)}%</span></div>`],
                            ]}
                            subTitleData={[returned_amount, news_amount]}
                            legendData={[
                                {color: '#73D389', title: t('loyal')},
                                {color: '#705AFB', title: t('new')}
                            ]}
                          />
                      </div>
                      <div className={styles.chart}>
                          <DonutChart
                            title={t('NSLoyal.transactions')}
                            data={[
                                ['x', 'y', {role: "tooltip", type: "string", p: {html: true}}],
                                [t('loyal'), Math.round(returned_transactions / (returned_transactions + news_transactions) * 100), `<div class='donutChartContainer_green'><span>${Math.round(returned_transactions / (returned_transactions + news_transactions) * 100)}%</span></div>`],
                                [t('new'), Math.round(news_transactions / (returned_transactions + news_transactions) * 100), `<div class='donutChartContainer_purple'><span>${Math.round(news_transactions / (returned_transactions + news_transactions) * 100)}%</span></div>`],
                            ]}
                            subTitleData={[returned_transactions, news_transactions]}
                            legendData={[
                                {color: '#73D389', title: t('loyal')},
                                {color: '#705AFB', title: t('new')}
                            ]}
                          />
                      </div>
                  </div>
              </>
              :
              <EmptyData marginTop={20}/>
            }
        </div>

    )
}