import {PENDING, SUCCESS, ERROR} from "../globalStatuses/globalStatuses";
import clientsTypes from "./clientsTypes";

const initialState = {
    clients: [],
    chosenClients: [],
    limit: 10,
    count: 0,
    status: '',
}

const clientsReducer = (state = initialState, action) => {
    switch (action.type) {

        case clientsTypes.GET_CLIENTS_PENDING:
            return {
                ...state,
                status: PENDING
            }

        case clientsTypes.GET_CLIENTS_SUCCESS:
            const transformClients = action.payload.customers.map(c => {
                c.checked = false;
                return c;
            });
            const detectedCheckedClients = transformClients.map(t => {
                const client = t;
                state.chosenClients.forEach((c) => t.id == c ? t.checked = true : null);
                return client;
            });

            return {
                ...state,
                clients: detectedCheckedClients,
                count: action.payload.count,
                status: SUCCESS
            }

        case clientsTypes.GET_CLIENTS_ERROR:
            return {
                ...state,
                status: ERROR
            }

        case clientsTypes.CHANGE_CLIENTS_PAGE:
            return {
                ...state,
                page: action.payload
            }

        case clientsTypes.CHANGE_CHOSEN_CLIENTS:
            const tempClients = state.clients.map(c => {
                if (c.id == action.payload.id) c.checked = action.payload.checked
                return c;
            });

            switch (action.payload.checked) {
                case true:
                    return {
                        ...state,
                        clients: tempClients,
                        chosenClients: [...state.chosenClients, action.payload.id]
                    }
                case false:
                    return {
                        ...state,
                        clients: tempClients,
                        chosenClients: state.chosenClients.filter(id => id !== action.payload.id)
                    }
            }
            return state;

        case clientsTypes.CLEAR_CHOSEN_CLIENTS:
            return {
                ...state,
                chosenClients: [],
                clients: state.clients.map(c => {
                    c.checked = false
                    return c;
                }),
            }

        default:
            return {
                ...state
            }
    }
}

export default clientsReducer
