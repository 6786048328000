import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import loyalActions from "../../../../store/analyticsStores/loyalStore/loyalActions";
import { convertDateToLongString } from "../../../../tools/convertDateToString";
import Loyal from "./components/Loyal/Loyal";

export default function LoyalContainer() {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { i18n } = useTranslation();

    const {
        data,
        status,
        returned_amount,
        returned_average_check,
        returned_clients,
        returned_transactions,
        news_amount,
        news_average_check,
        news_clients,
        news_transactions,
        type,
    } = useSelector(state => state.loyalReducer)

    const {info} = useSelector(state => state.branchReducer)
    const dispatch = useDispatch()

    const changePeriod = (period) => {
        dispatch(loyalActions.changeDataPeriod(period))
    }

    useEffect(() => {
        if (!startDate && !endDate) return

        let queryParams = ``;
        if (info && (startDate && startDate.toString()) !== (endDate && endDate.toString())) {
            if (startDate) queryParams += `&from=${convertDateToLongString(startDate).split('.').join('-')}`;
            if (endDate) queryParams += `&to=${convertDateToLongString(endDate).split('.').join('-')}`;

            if (startDate || endDate) dispatch(loyalActions.getLoyalData(info.id, queryParams));
        } else if (info && (startDate && startDate.toString()) === (endDate && endDate.toString())) dispatch(loyalActions.getLoyalData((info.id, queryParams)))
    }, [startDate, endDate]);

    useEffect(() => {
        if (info) {
            setStartDate(null);
            setEndDate(null);
            dispatch(loyalActions.getLoyalData(info && info.id, `type=${type}`));
        }
    }, [info, type])


    return (
        <Loyal
            data={data}
            status={status}
            returned_amount={returned_amount}
            returned_average_check={returned_average_check}
            returned_clients={returned_clients}
            returned_transactions={returned_transactions}
            news_amount={news_amount}
            news_average_check={news_average_check}
            news_clients={news_clients}
            news_transactions={news_transactions}
            changePeriod={changePeriod}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            type={type}
        />
    )
}