import loyalApi from "./loyalApi";
import loyalTypes from "./loyalTypes";

const loyalActions = {
    getLoyalData: (branch_id, params) => async dispatch => {
        dispatch({
            type: loyalTypes.LA_GET_DATA_PENDING
        })
        await loyalApi.getLoyalData(branch_id, params)
            .then((res) => {
                const data = res.data
                let returned_amount = 0
                let returned_average_check = 0
                let returned_clients = 0
                let returned_transactions = 0
                let news_amount = 0
                let news_average_check = 0
                let news_clients = 0
                let news_transactions = 0

                for (let i in data) {
                    if (data[i].client_type === "RETURNED") {
                        returned_clients += 1
                        returned_amount += Number(data[i].payment_total)
                        returned_transactions += data[i].payment_count
                    } else if (data[i].client_type === "NEW") {
                        news_clients += 1
                        news_amount += data[i].payment_total
                        news_transactions += data[i].payment_count
                    }
                }

                returned_average_check = returned_transactions && Math.round(returned_amount / returned_transactions)
                news_average_check = news_transactions && Math.round(news_amount / news_transactions)

                dispatch({
                    type: loyalTypes.LA_GET_DATA_SUCCESS,
                    payload: {
                        data,
                        returned_amount: Math.round(returned_amount),
                        returned_average_check: Math.round(returned_average_check),
                        returned_clients: Math.round(returned_clients),
                        returned_transactions: Math.round(returned_transactions),
                        news_amount: Math.round(news_amount),
                        news_average_check: Math.round(news_average_check),
                        news_clients: Math.round(news_clients),
                        news_transactions: Math.round(news_transactions)
                    }
                })
            })
            .catch(() => {
                dispatch({
                    type: loyalTypes.LA_GET_DATA_ERROR
                })
            })
    },
    changeDataPeriod: (period) => {
        return {
            type: loyalTypes.LA_CHANGE_DATA_PERIOD,
            payload: period
        }
    }
}

export default loyalActions