import instance, { API_URL } from "../../../api";
import cookies from "react-cookies";
import { COOKIES } from "../../../helpers";

const businessReportApi = {
    getBusinessReportData: async (branch_id, params) => {
        return await instance().get(`/analytics/branches/${branch_id}?${params}`).then(res => res.data)
    },

    getBusinessReportDataExport: (branchId, params) => {
        let newparams = ``;
        if (params.branch_name) {
            newparams = `?branch_name=${params.branch_name}&`
        }
        if (params.from) {
            if (newparams.length) {
                newparams += `from=${params.from}&`
            } else {
                newparams = `?from=${params.from}&`
            }
        }
        if (params.to) {
            if (newparams.length) {
                newparams += `to=${params.to}`
            } else {
                newparams = `?to=${params.to}`
            }
        }
        return fetch(`${API_URL}/analytics/branches/${branchId}/export${newparams}`, {
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
                Authorization: `Bearer ${cookies.load(COOKIES.TOKEN)}`
            },
            method: 'GET'
        }).then((response) => {
            response.blob().then((blob) => {
                const filename = decodeURI(response.headers.get('Content-Disposition')?.split('filename=')[1] || '')
                if ('msSaveOrOpenBlob' in window.navigator) {
                    navigator.msSaveBlob(blob, filename)
                } else {
                    const a = document.createElement('a')
                    document.body.appendChild(a)
                    a.href = window.URL.createObjectURL(blob)
                    a.download = filename
                    a.target = '_blank'
                    a.click()
                    a.remove()
                }
            });

            return {};
        })
    },
    getStatisticsRfmAnalysisExcel: (branchId, client_type) => {
        return fetch(`${API_URL}/branches/${branchId}/statistics/rfm_analysis/excel?client_type=${client_type}`, {
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/vnd.ms-excel;charset=UTF-8',
                Authorization: `Bearer ${cookies.load(COOKIES.TOKEN)}`
            },
            method: 'GET'
        }).then((response) => {
            response.blob().then((blob) => {
                const filename = decodeURI(response.headers.get('Content-Disposition')?.split('filename=')[1] || '')
                if ('msSaveOrOpenBlob' in window.navigator) {
                    navigator.msSaveBlob(blob, filename)
                } else {
                    const a = document.createElement('a')
                    document.body.appendChild(a)
                    a.href = window.URL.createObjectURL(blob)
                    a.download = filename.split('.')[0]
                    a.target = '_blank'
                    a.click()
                    a.remove()
                }
            });

            return {};
        })
    }
}

export default businessReportApi