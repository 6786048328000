// What index of the cleaned value to insert a string before
// const insertions = { 0: '(', 3: ') ', 6: ' ', 8: ' ' };
const insertions = {};
// The maximum length WITHOUT insertions
const maxPrunedLength = 11;

function mapString(original, fn) {
  return original.split('').map(fn).join('');
}

export function onlyDigits(value = '') {
  if (typeof value === 'string') {
    const pruned = value.replace(/\D/g, '');
    return pruned.slice(0, maxPrunedLength);
  }
  return '';
}

export const phoneMask = (value) => {
  const digits = onlyDigits(value);
  if (digits) {
    return mapString(digits, (character, index) => {
      const insertion = insertions[index] || '';
      return insertion + character;
    });
  }
  return '';
};

export const clearMask = (value, length = 11) => {
  value = value.replace(/\D/g, '');

  if (value.length === length) {
    return value.substring(0);
  }

  return value;
};

export const mask = (value, mask = '###', length = 11) => {
  let i = 0;
  let lastReplacedIndex = -1;

  value = clearMask(value, length);

  const filledMask = mask.replace(/#/g, (_, j) => {
    if (i >= value.length) {
      return '#';
    }

    lastReplacedIndex = j;

    return value[i++];
  });

  return filledMask.substring(0, lastReplacedIndex + 1);
};

export const hasResponseErrorMessage = (error) => {
  return error.response && error.response.data
}

const dateFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };

export const formatDate = (date, options = dateFormatOptions) => {
  return new Intl.DateTimeFormat('ru-RU', options).format(date);
}

export function kFormatter(num) {
  return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}

export function computePercent(x, y) {
  return y ? (x / y * 100).toFixed(1) : 0;
}

export const paymentType = {
  Visa:  "VISA",
  MasterCard : "MASTERCARD",
  Kaspi: "KASPI",
  ApplePay: "APPLE_PAY",
  GooglePay: "GOOGLE_PAY",
  Cash: "CASH",
}

export const COOKIES = {
  TOKEN: 'token',
  NONCE: 'nonce',
  PHONE: 'phone',
  REMEMBER: 'remember',
  ROLES: 'roles',
};

const day = ['day', 'fewDays', 'days'];
const year = ['year', 'fewYears', 'years'];
const hour = ['hour',  'fewHours', 'hours'];
const minute = ['minute', 'fewMinutes', 'minutes'];

const pluralTitles = {
  day,
  year,
  hour,
  minute,
}

export function plural(number, title) {
  const titles = pluralTitles[title];
  const cases = [2, 0, 1, 1, 1, 2];
  return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
}