import instance from "../../api";
import queryString from "querystring";

const clientsApi = {
    getClients: (id, params = {}) => {
        const newParams = {};
        Object.keys(params).forEach((key) => {
            if (params[key] !== null && params[key] !== "") {
                if (key === "startDate") {
                    newParams["from"] = params[key];
                }
                if (key === "endDate") {
                    newParams["to"] = params[key];
                }
                newParams[key] = params[key];
            }
        })
        const prms = queryString.stringify(newParams, '&', '',{
            skipNull: true,
            skipEmptyString: true
        })

        return instance().get(`/branches/${id}/customers?${prms}`).then(res => res.data)
    },
    getAnalyticClients: (groupId, params = {}, invisParams = {}) => {
        const newParams = {};
        Object.keys(params).forEach((key) => {
            if (params[key] !== null && params[key] !== "") {
                if (key === "startDate") {
                    newParams["from"] = params[key];
                }
                if (key === "endDate") {
                    newParams["to"] = params[key];
                }
                newParams[key] = params[key];
            }
        })

        let invisQuery = ``

        if (invisParams.city_ids?.length) {
            invisQuery = `&${queryString.stringify({city_ids: invisParams.city_ids.map((city) => city.value)})}`
        }
        if (invisParams.branch_ids?.length) {
            invisQuery = `${invisQuery}&${queryString.stringify({branch_ids: invisParams.branch_ids.map((branch) => branch.value)})}`
        }

        const prms = queryString.stringify(newParams, '&', '',{
            skipNull: true,
            skipEmptyString: true
        })

        return instance().get(`/branches/group/${groupId}/customers?${prms}${invisQuery}`).then(res => res.data)
    },
    getClientsDatabase: (id, params = {}) => {
        const newParams = {};
        Object.keys(params).forEach((key) => {
            if (params[key] !== null && params[key] !== "") {
                if (key === "startDate") {
                    newParams["from"] = params[key];
                }
                if (key === "endDate") {
                    newParams["to"] = params[key];
                }
                newParams[key] = params[key];
            }
        })
        const prms = queryString.stringify(newParams, '&', '',{
            skipNull: true,
            skipEmptyString: true
        })

        return instance().get(`/branches/${id}/customers/uploaded?${prms}`).then(res => res.data)
    },
    getAnalyticClientsDatabase: (groupId, params = {}, invisParams) => {
        const newParams = {};
        Object.keys(params).forEach((key) => {
            if (params[key] !== null && params[key] !== "") {
                if (key === "startDate") {
                    newParams["from"] = params[key];
                }
                if (key === "endDate") {
                    newParams["to"] = params[key];
                }
                newParams[key] = params[key];
            }
        })
        const prms = queryString.stringify(newParams, '&', '',{
            skipNull: true,
            skipEmptyString: true
        })

        let invisQuery = ``

        if (invisParams.city_ids?.length) {
            invisQuery = `&${queryString.stringify({city_ids: invisParams.city_ids.map((city) => city.value)})}`
        }
        if (invisParams.branch_ids?.length) {
            invisQuery = `${invisQuery}&${queryString.stringify({branch_ids: invisParams.branch_ids.map((branch) => branch.value)})}`
        }

        return instance().get(`/branches/group/${groupId}/customers/uploaded?${prms}${invisQuery}`).then(res => res.data)
    },
    putClient: (userId, body = {}) => {
        return instance().put(`/customers/${userId}/personal-info`, body).then(res => res?.data)
    }
}

export default clientsApi
